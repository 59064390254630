<template>
  <div class="livestreaming">
    <img src="../../assets/product/livestreaming/1.png" class="headerimg" />

    <div class="private">
      <div class="title">私域流量电商直播</div>
      <div class="title2">
        全渠道引流获客，直播营销互动，加速变现转化，提升用户留存，实现搭建专属私域电商直播平台
      </div>

      <div class="privatelist">
        <div class="privatecard">
          <div class="privatetitle">渠道引流</div>

          <div class="privatetabel">
            <div class="privateinfo">
              <div class="infotitle">线上</div>
              <div class="infotext">小程序 <span>微信</span> 企业微信</div>
            </div>

            <div class="privateinfo infotop">
              <div class="infotitle">线下</div>
              <div class="infotext2">店铺 <span>导购</span></div>
            </div>
          </div>
        </div>

        <div class="privatecard">
          <div class="privatetitle">营销转化</div>

          <div class="privatetabel">
            <div class="privateinfo2 bodercol">
              <div>内容预热</div>
              <div class="infotitle titlebord">直播预约</div>
              <div>分享裂变</div>
            </div>
          </div>
        </div>

        <div class="privatecard">
          <div class="privatetitle">直播变现</div>

          <div class="privatetabel">
            <div class="privateinfo2 bodercol1">
              <div>直播互动</div>
              <div class="infotitle titlebord1">直播带货</div>
              <div>促销优惠</div>
            </div>
          </div>
        </div>

        <div class="privatecard">
          <div class="privatetitle">用户留存</div>

          <div class="privatetabel">
            <div class="privateinfo2 bodercol2">
              <div>渠道分析</div>
              <div class="infotitle titlebord2">用户分析</div>
              <div>经营分析</div>
            </div>
          </div>
        </div>

        <div class="privatecard">
          <div class="privatetitle">持续复购</div>

          <div class="privatetabel">
            <div class="privateinfo2 bodercol3">
              <div>物流发货</div>
              <div class="infotitle titlebord3">到店转单</div>
              <div>反复触达</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">直播电商带货方案</div>
      <div class="title2" style="color: #000000">
        多种应用搭配，构建线上线下营销闭环
      </div>

      <div class="caselist">
        <div class="casecard">
          <div class="casetitle">吸引用户</div>
          <div class="caseinfo">
            通过“短视频”关联商品，快速筛选用户，持续导购
          </div>
        </div>

        <div class="casecard">
          <div class="casetitle">沉淀流量</div>
          <div class="caseinfo">
            结合“活码”，打造个人微信(群)吸引用户沉淀用户
          </div>
        </div>

        <div class="casecard">
          <div class="casetitle">线上成交</div>
          <div class="caseinfo">
            直播+生态云电商直播功能组件，促进线上成交，如：秒杀
          </div>
        </div>
      </div>

      <div class="caselist casetop">
        <div class="casecard">
          <div class="casetitle">线下成交</div>
          <div class="caseinfo">
            通过“短视频”关联商品，快速筛选用户，持续导购
          </div>
        </div>

        <div class="casecard">
          <div class="casetitle">裂变用户</div>
          <div class="caseinfo">
            结合电商标配“分销奖励模式”，能够快速裂变用户
          </div>
        </div>

        <div class="casecard">
          <div class="casetitle">线上线下闭环</div>
          <div class="caseinfo">
            结合“积分商城”直播导购线下消费，奖励积分线上兑换
          </div>
        </div>
      </div>

      <div class="caselist">
        <div class="casecard">
          <div class="casetitle">丰富平台商品</div>
          <div class="caseinfo">
            对接“供应链系统解决直播电商”货源问题，提升平台销售额
          </div>
        </div>

        <div class="casecard">
          <div class="casetitle">加强品牌认知</div>
          <div class="caseinfo">
            结合“邦伲德官网”以图文、视频展示品牌咨询，强化企业形象
          </div>
        </div>

        <div class="casecard">
          <div class="casetitle">更多</div>
          <div class="caseinfo">
            近50+种营销应用，可任意组建搭配
            <div>……</div>
          </div>
        </div>
      </div>
    </div>

    <div class="der">
      <div
        class="derlist"
        v-for="(item, index) in list"
        :key="index"
        :class="index % 2 == 1 ? 'bacolor' : ''"
      >
        <div class="dercard dertop" v-if="index % 2 == 0">
          <div class="derimg">
            <img :src="item.imgurl" alt="" />
          </div>
          <div class="derinfo">
            <div class="dertitle">
              {{ item.title }}
              <div>{{ item.description }}</div>
            </div>
            <div class="dertext">
              {{ item.info }}
            </div>
          </div>
        </div>
        <div class="dercard derpadd" v-else>
          <div class="derinfo">
            <div class="dertitle">
              {{ item.title }}
              <div>{{ item.description }}</div>
            </div>
            <div class="dertext">
              {{ item.info }}
            </div>
          </div>
          <div class="derimg">
            <img :src="item.imgurl" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="escort">
      <div class="title">邦伲德为你保驾护航</div>

      <div class="escortlist">
        <div class="escortcard">
          <div class="escortimg">
            <img src="../../assets/product/livestreaming/6.png" alt="" />
          </div>

          <div class="estitle">稳定安全</div>
          <div class="escortinfo">
            多维度提供专业直播稳定性保障，支持多种直播格式。
          </div>
          <div class="escortline"></div>
          <div class="escorttitle">高清稳定流畅</div>
          <div class="escorttitle">超低延迟</div>
          <div class="escorttitle">智能扩容</div>
          <div class="escorttitle">内容多重过滤</div>
        </div>

        <div class="escortcard">
          <div class="escortimg">
            <img src="../../assets/product/livestreaming/7.png" alt="" />
          </div>

          <div class="estitle">闭环产品</div>
          <div class="escortinfo">
            产品迭代速度快，多种直播营销互动玩法,赋能品牌交易转化和用户运营。
          </div>
          <div class="escortline"></div>
          <div class="escorttitle">个性化直播间</div>
          <div class="escorttitle">60+营销玩法</div>
          <div class="escorttitle">多平台分发</div>
          <div class="escorttitle">大数据分析</div>
        </div>

        <div class="escortcard">
          <div class="escortimg">
            <img src="../../assets/product/livestreaming/8.png" alt="" />
          </div>

          <div class="estitle">即速服务</div>
          <div class="escortinfo">
            365天全年无休，为您提供高效的沟通渠道，全过程顾问式服务，助力企业远航。
          </div>
          <div class="escortline"></div>
          <div class="escorttitle">7*24小时响应问题</div>
          <div class="escorttitle">多渠道售后支持</div>
          <div class="escorttitle">专业运营指导</div>
          <div class="escorttitle">直播报备全程保障</div>
        </div>
      </div>
    </div>

    <div class="praise">
      <div class="title">他们都在用邦伲德，线上线下结合高效变现</div>
      <div class="praiselist">
        <img src="../../assets/product/livestreaming/9.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          imgurl: require("../../assets/product/livestreaming/2.png"),
          title: "多渠道直播预热",
          description: "快速引流获客",
          info: "提前曝光直播福利，嵌入公众号推文信息和菜单独立小程序，短信直播消息推送、社群群发，朋友圈直播间首页开启活动预热",
        },
        {
          imgurl: require("../../assets/product/livestreaming/3.png"),
          title: "丰富定制化内容展示形",
          description: "式，提高流量转化",
          info: "定制直播专题页、预热页，微官网， 自定义商城装修等定制内容形式， 满足不同渠道场景推广，流量沉淀无外流",
        },
        {
          imgurl: require("../../assets/product/livestreaming/4.png"),
          title: "百余种营销玩法",
          description: "带动高活跃高转化",
          info: "拼团、砍价、直播等多种营销插件组合营销，刺激客户购买，带动商家业绩高效增长",
        },
        {
          imgurl: require("../../assets/product/livestreaming/5.png"),
          title: "品牌专属电商直播间",
          description: "满足各场景带货",
          info: "一键定制直播界面和外观风格， 实现直播导购、产品平铺展示、新品发布、商品实时推送， 在线演示，打造品牌专属直播间， 满足各类个性化带货场景",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.praise {
  .praiselist {
    margin-top: 60px;
    margin-bottom: 140px;
    text-align: center;
    img {
      width: 1400px;
    }
  }
}

.escort {
  margin: 140px auto;
  .escortlist {
    margin: auto;
    margin-top: 104px;
    width: 73%;
    display: flex;
    justify-content: space-between;
    .escortcard {
      width: 430px;
      height: 425px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
      border-radius: 10px;
      text-align: center;
      padding: 0px 30px;
      box-sizing: border-box;
      position: relative;
      .escortimg {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: -44px;
        img {
          width: 88px;
        }
      }
      .estitle {
        margin-top: 65px;
        font-size: 24px;
        font-weight: 500;
      }
      .escorttitle {
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;
      }
      .escortinfo {
        font-size: 20px;
        font-weight: 400;
        color: #616b80;
        margin-top: 20px;
      }
      .escortline {
        width: 54px;
        height: 6px;
        background: linear-gradient(180deg, #fda533 0%, #fcca7e 100%);
        border-radius: 3px;
        margin: 20px auto;
      }
    }
  }
}

.der {
  .bacolor {
    background: #f5f7fa;
  }
  .dertop {
    margin: 140px auto !important;
  }
  .derpadd {
    padding: 94px 0px;
  }
  .derlist {
    .dercard {
      margin: auto;
      width: 73%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .derimg {
        img {
          width: 531px;
        }
      }
      .derinfo {
        width: 700px;
        .dertitle {
          font-size: 36px;
          font-weight: 500;
          color: #333333;
        }
        .dertext {
          font-size: 24px;
          font-weight: 500;
          color: #616b80;
          margin-top: 40px;
        }
      }
    }
  }
}

.case {
  margin-top: 140px;
  background: #f5f7fa;
  padding: 90px 0;
  .casetop {
    margin: 42px auto;
  }
  .caselist {
    margin: auto;
    margin-top: 52px;
    width: 73%;
    display: flex;
    justify-content: space-between;
    .casecard {
      width: 341px;
      height: 176px;
      border-radius: 10px;
      background: #ffffff;
      .casetitle {
        text-align: center;
        margin-top: 42px;
        font-size: 24px;
        font-weight: 500;
        color: #2e7df9;
      }
      .caseinfo {
        margin: auto 36px;
        margin-top: 14px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #9e9e9e;
      }
    }
  }
}

.private {
  margin-top: 90px;
  .privatelist {
    margin: auto;
    margin-top: 60px;
    width: 73%;
    display: flex;
    justify-content: space-between;
    .privatecard {
      .privatetitle {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #000000;
      }

      .privatetabel {
        margin-top: 30px;
        .infotop {
          margin-top: 10px;
        }
        .bodercol {
          color: #002080;
          background: #f5f9ff;
          border: 1px solid #ddecff;
        }

        .bodercol1 {
          color: #006c2b;
          background: #d8f2e2;
          border: 1px solid #d8f2e2;
        }

        .bodercol2 {
          color: #900000;
          background: #ffe2e2;
          border: 1px solid #ffe2e2;
        }

        .bodercol3 {
          color: #0c246f;
          background: #f5f9ff;
          border: 1px solid #f5f9ff;
        }

        .privateinfo2 {
          width: 233px;
          height: 272px;
          border-radius: 6px;
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          padding: 30px;
          box-sizing: border-box;
          .titlebord {
            border-top: 1px solid #ebf4ff;
            border-bottom: 1px solid #ebf4ff;
          }
          .titlebord1 {
            border-top: 1px solid #e5f2f5;
            border-bottom: 1px solid #e5f2f5;
          }
          .titlebord2 {
            border-top: 1px solid #f2ecf4;
            border-bottom: 1px solid #f2ecf4;
          }
          .titlebord3 {
            border-top: 1px solid #eaf4ff;
            border-bottom: 1px solid #eaf4ff;
          }
          .infotitle {
            margin: 30px 0;
            padding: 30px 0px;
          }
        }

        .privateinfo {
          min-width: 233px;
          min-height: 128px;
          max-height: 128px;
          background: #fff4f4;
          border-radius: 6px;
          border: 1px solid #ffdcdc;
          color: #950c0c;
          .infotitle {
            margin-top: 30px;
            text-align: center;
            font-size: 24px;
            font-weight: 400;
          }

          .infotext {
            margin: 30px 24px;
            font-size: 14px;
            font-weight: 500;
            span {
              margin-left: 28px;
              margin-right: 20px;
            }
          }
          .infotext2 {
            margin: 30px 60px;
            font-size: 14px;
            font-weight: 500;
            span {
              margin-left: 48px;
            }
          }
        }
      }
    }
  }
}
</style>
